import React, { useContext } from 'react';
import classNames from 'classnames';
import brandPromiseSvgLogo from '../../../assets/logos/john-lewis-partners.svg';
import teaseSvgLogo from '../../../assets/logos/john-lewis-logo-linear.svg';
import logoPride from '../../../assets/logos/JL_Pride_Logo_2024.svg';
import logoXmas from '../../../assets/logos/john-lewis-christmas-2023.svg';
import styles from './logobp.scss';
import EnvVarsFeaturesContext from '../../scaffold/EnvVarsFeaturesContext';
import isMonetateGroup from '../../utils/monetate/isMonetateGroup';

export const logoAssetsPath = assetsPath => asset =>
  assetsPath ? `${assetsPath}/images/${asset.split('/').pop()}` : asset;

const getLogoSrc = (
  assetsPath,
  teaseLogoActive,
  prideLogoActive,
  xmasLogoActive
) => {
  const logoWithAssetsPath = logoAssetsPath(assetsPath);
  if (teaseLogoActive) return logoWithAssetsPath(teaseSvgLogo);
  if (prideLogoActive) return logoWithAssetsPath(logoPride);
  if (xmasLogoActive) return logoWithAssetsPath(logoXmas);
  return logoWithAssetsPath(brandPromiseSvgLogo);
};

const LogoBP = ({ splashScreen, isTabbedNavActive }) => {
  const { envVars, features } = useContext(EnvVarsFeaturesContext);
  const teaseLogoActive = isMonetateGroup(features, 'enable tease logo');
  const prideLogoActive = isMonetateGroup(features, 'enable pride logo');
  const xmasLogoActive = isMonetateGroup(features, 'enable christmas logo');
  const logoSrc = getLogoSrc(
    envVars?.assetsPath || '',
    teaseLogoActive,
    prideLogoActive,
    xmasLogoActive
  );

  return (
    <div
      className={classNames(styles.container, {
        [styles.container__expandedSearch]: splashScreen,
        [styles.container__tease]: teaseLogoActive,
        [styles.container__tn]: isTabbedNavActive,
      })}
      data-testid="logo-container"
    >
      <a className={styles.link} href="/">
        <img
          src={logoSrc}
          alt="John Lewis & Partners Home"
          className={classNames(styles.logo, {
            [styles.logo__tn]: isTabbedNavActive,
          })}
        />
      </a>
    </div>
  );
};

export default LogoBP;

/* eslint-disable import/no-named-as-default, react/sort-comp */
import React from 'react';
import classNames from 'classnames';
import dispatchNewEvent from '../../utils/analytics/dispatchEvent';
import postMonetateImpression from '../../utils/monetate/postMonetateImpression';
import { TopLinks } from '../link-list/LinkList';
import { getTopLinks, getTopLinksWithMenu } from '../links';
import LogoBP from '../logo/LogoBP';
import UserTools from '../user-tools/UserTools';
import Search from '../search/Search';
import {
  desktopMediaQuery,
  isDesktop,
} from '../../utils/device/identifyDevice';
import isMonetateGroup from '../../utils/monetate/isMonetateGroup';
import EnvVarsFeaturesContext from '../../scaffold/EnvVarsFeaturesContext';
import dispatchMonetateQEvent from '../../utils/monetate/monetateEventDispatcher';

import styles from './responsive-header.scss';
import BrandPromiseOverlay from '../typeahead-overlay/BrandPromiseOverlay';
import BrandPromiseCloseButton from '../typeahead-overlay/BrandPromiseCloseButton';

const peepHideThreshold = 150;
const peepShowThreshold = 100;

export class ResponsiveHeader extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showNav: false,
      previousScrollPosition: 0,
      wasScrollingUp: false,
      previousDirectionChange: 0,
      isVisible: true,
      isFocused: false,
    };
    const {
      features,
      envVars,
      appDownloadBanner: appDownloadBannerActive,
      preNavRedesign: isNewPreNavVariantActive,
      fallbackMenu: isFallbackMenuTestActive,
    } = this.context;
    this.peepHeader = features.peepHeader;
    this.monetateExperiences = features.monetateExperiences;
    this.isPersistMobileSearch = isMonetateGroup(
      features,
      'persist-mobile-search'
    );
    this.isTabbedNavActive = isMonetateGroup(features, 'tabbed-nav-design');
    this.isGroupedActive = isMonetateGroup(features, 'stacked-categories');
    this.monetate = envVars.monetate;
    this.handleDeviceOrientation = this.handleDeviceOrientation.bind(this);
    this.updateView = this.updateView.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.notifyMonetate = this.notifyMonetate.bind(this);
    this.searchInputFocus = this.searchInputFocus.bind(this);
    this.escKeyHandler = this.escKeyHandler.bind(this);
    this.searchInputBlur = this.searchInputBlur.bind(this);
    this.onSearchTabOut = this.onSearchTabOut.bind(this);
    this.handleUnpeep = this.handleUnpeep.bind(this);
    this.onlyPeepAtTop = features.onlyPeepAtTop || false;
    this.scrollbarWidth = 0;
    this.header = React.createRef();
    this.headerContainer = React.createRef();
    this.recordImpression = isMonetateGroup(features, 'record-impression');
    this.isNewPreNavVariantActive = isNewPreNavVariantActive;
    this.isWhiteTypeaheadActive = true;

    if (this.isNewPreNavVariantActive === 'Experiment') {
      this.topLinksData = getTopLinksWithMenu(envVars?.accountBaseUrl);
    } else {
      this.topLinksData = getTopLinks(envVars?.accountBaseUrl);
    }
    this.appDownloadBannerActive = appDownloadBannerActive || false;

    this.isFallbackMenuTestActive = isFallbackMenuTestActive;
  }

  componentDidMount() {
    this.scrollbarWidth = window.innerWidth - document.body.clientWidth;
    this.updateView();
    window.addEventListener('scroll', this.updateView);
    window.addEventListener('keydown', this.escKeyHandler);

    this.handleDeviceOrientation();
    window.addEventListener(
      'jl-events.browser.mobileHeaderMenuClick',
      this.notifyMonetate,
      { once: true }
    );
    window.addEventListener(
      'jl-events.browser.mobileHeaderCloseMenu',
      this.onMenuButtonClick
    );
    window.addEventListener(
      'jl-events.browser.mobileHeaderUnpeep',
      this.handleUnpeep
    );
    desktopMediaQuery().addListener(this.handleDeviceOrientation);

    if (this.recordImpression) {
      postMonetateImpression(
        this.monetate,
        'record-impression',
        this.monetateExperiences
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateView);
    window.removeEventListener('keydown', this.escKeyHandler);
    window.removeEventListener(
      'jl-events.browser.mobileHeaderMenuClick',
      this.notifyMonetate,
      { once: true }
    );
    window.removeEventListener(
      'jl-events.browser.mobileHeaderCloseMenu',
      this.onMenuButtonClick
    );
    window.removeEventListener(
      'jl-events.browser.mobileHeaderUnpeep',
      this.handleUnpeep
    );
  }

  onMenuButtonClick(e = null, persistSearch = false) {
    let showNav;

    if (persistSearch) {
      showNav = false;
    } else {
      showNav = !this.state.showNav;
    }

    if (e) e.preventDefault();

    this.setState({ showNav });
    this.props.updateMobileNavState(showNav);

    const menuClick = new CustomEvent(
      'jl-events.browser.mobileHeaderMenuClick',
      {
        detail: {
          hide: showNav,
        },
      }
    );

    window.dispatchEvent(menuClick);

    if (showNav) {
      window.document.body.classList.add('lockBody');
    } else {
      window.document.body.classList.remove('lockBody');
    }

    this.triggerMenuButtonAnalytics();
  }

  onSearchTabOut(e) {
    if (e?.relatedTarget?.id.includes('desktop-menu-item')) {
      this.searchInputBlur();
    }
  }

  setVisibility() {
    const {
      previousScrollPosition,
      previousDirectionChange,
      wasScrollingUp,
      isVisible,
      showNav,
    } = this.state;

    if (isDesktop()) {
      this.setState({ isVisible: true });
      return;
    }

    const currentScrollPosition = window.pageYOffset;
    const isCurrentlyScrollingUp =
      currentScrollPosition < previousScrollPosition;
    const newVisibilityState = {};

    const scrollDirectionDidChange = () =>
      wasScrollingUp !== isCurrentlyScrollingUp;

    const scrollPositionOffTopOfPage = () => currentScrollPosition < 0;

    const headerAtTopOfScreen = () => currentScrollPosition < peepHideThreshold;

    const updatePreviousDirectionChange = () => {
      if (scrollDirectionDidChange()) {
        return scrollPositionOffTopOfPage() || showNav
          ? previousDirectionChange
          : currentScrollPosition;
      }
      return previousDirectionChange;
    };

    const scrolledUpPastPeepThreshold = () => {
      const showThreshold = previousDirectionChange - peepShowThreshold;

      let showOnScrollUp = isCurrentlyScrollingUp;
      if (this.onlyPeepAtTop) {
        showOnScrollUp = false;
      }

      return (
        !isVisible && showOnScrollUp && currentScrollPosition < showThreshold
      );
    };

    const scrolledDownPastPeepThreshold = () => {
      const hideThreshold = previousDirectionChange + peepHideThreshold;

      return (
        isVisible &&
        !isCurrentlyScrollingUp &&
        currentScrollPosition > hideThreshold
      );
    };

    const showHeader = () => {
      newVisibilityState.isVisible = true;
      this.dispatchPeepEvent(newVisibilityState.isVisible);
    };

    const hideHeader = () => {
      newVisibilityState.isVisible = false;
      this.dispatchPeepEvent(newVisibilityState.isVisible);
    };

    if (scrolledUpPastPeepThreshold() || headerAtTopOfScreen()) {
      showHeader();
    }

    if (scrolledDownPastPeepThreshold()) {
      hideHeader();
    }

    this.setState({
      ...newVisibilityState,
      previousDirectionChange: updatePreviousDirectionChange(),
      wasScrollingUp: isCurrentlyScrollingUp,
      previousScrollPosition: scrollPositionOffTopOfPage()
        ? 0
        : currentScrollPosition,
    });
  }

  triggerMenuButtonAnalytics() {
    dispatchNewEvent('dataStateChange', {
      action: 'topNav - Side Flyout',
      type: this.state.showNav ? 'Closed' : 'Open',
    });
    if (this.state.showNav) {
      dispatchMonetateQEvent('mobile-menu-click.menu-close');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  dispatchPeepEvent(peeped) {
    const event = new CustomEvent('jl-events.browser.mobileHeaderPeepUpdated', {
      detail: { peeped },
    });

    window.dispatchEvent(event);
  }

  notifyMonetate() {
    postMonetateImpression(
      this.monetate,
      'burger-menu-opened',
      this.monetateExperiences
    );
    postMonetateImpression(
      this.monetate,
      'remove-dont-miss',
      this.monetateExperiences
    );
  }

  handleUnpeep() {
    if (!isDesktop() && window.pageYOffset >= peepHideThreshold) {
      this.dispatchPeepEvent(false);
      this.setState({ isVisible: false });
    }
  }

  handleDeviceOrientation() {
    if (isDesktop()) {
      this.props.updateMobileNavState(false);
      window.document.body.classList.remove('lockBody');
    }
    this.setState({ showNav: false });
  }

  handleOverlayKeyPress(event) {
    if (event.key === 'Enter') {
      this.onMenuButtonClick(event);
    }
  }

  updateView() {
    if (!this.state.isFocused) {
      requestAnimationFrame(() => {
        this.setVisibility();
      });
    }
  }

  searchInputFocus() {
    window.scrollTo(0, 0);
    this.setState({ isFocused: true });
    // Old styling - only apply to desktop
    if (isDesktop()) {
      document.body.classList.add(styles.typeaheadOpen);
      document.body.style.paddingRight = `${this.scrollbarWidth}px`;
    }
    postMonetateImpression(
      this.monetate,
      'typeahead-content',
      this.monetateExperiences
    );
    postMonetateImpression(
      this.monetate,
      'typeahead-recs',
      this.monetateExperiences
    );

    postMonetateImpression(
      this.monetate,
      'typeahead-white',
      this.monetateExperiences
    );

    if (this.isPersistMobileSearch) {
      this.onMenuButtonClick(null, true);
    }
  }

  escKeyHandler(event) {
    if (event.keyCode === 27) {
      document.activeElement.blur();
      this.searchInputBlur();
    }
  }

  searchInputBlur() {
    this.setState({ isFocused: false });
    // Old styling - only apply to desktop
    if (isDesktop()) {
      document.body.style.paddingRight = '';
      document.body.classList.remove(styles.typeaheadOpen);
    } else {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { showNav, isVisible } = this.state;

    const wrapperClasses = classNames(styles.header, {
      [styles.navOpenPersistent]: showNav,
      [styles.header__tn]: this.isTabbedNavActive,
      [styles.header__app_banner]:
        this.appDownloadBannerActive && this.props.showAppDownloadBanner,
    });

    const overlayClasses = classNames(styles.overlay, {
      [styles.showOverlay]: showNav,
    });

    const splashClasses = this.state.isFocused
      ? classNames(styles.expandedSearch)
      : classNames(styles.search);

    const containerClasses = classNames(styles.container, {
      [styles.peepHeader]: this.peepHeader,
      [styles.peep]: this.peepHeader && !isVisible && !showNav,
      [styles.container__tn]: this.isTabbedNavActive,
      [styles.container__app_banner]: this.appDownloadBannerActive,
      [styles.container__app_banner__visible]:
        this.appDownloadBannerActive && this.props.showAppDownloadBanner,
    });

    const typeaheadSeparatorClasses = classNames(styles.typeaheadSeparator, {
      [styles.typeaheadSeparator__hidden]: !this.state.isFocused,
      [styles.typeaheadSeparator__hidden__doTransition]: !this.state.isFocused,
    });

    return (
      <div
        className={wrapperClasses}
        ref={this.headerContainer}
        data-testid="header-wrapper"
      >
        <div
          className={containerClasses}
          ref={this.header}
          data-testid="header-container"
        >
          <div
            className={classNames(styles.topBar, {
              [styles.topBar__prenav]:
                this.isNewPreNavVariantActive === 'Experiment',
              [styles.topBar__active]: this.state.isFocused,
              [styles.topBar__tn]: this.isTabbedNavActive,
            })}
            data-testid="header-top-bar"
            onFocus={this.state.isFocused ? this.searchInputBlur : null}
          >
            <div
              className={classNames(styles.topLinks, {
                [styles.topLinks__prenav]:
                  this.isNewPreNavVariantActive === 'Experiment',
                [styles.topLinks__tn]: this.isTabbedNavActive,
              })}
              data-testid="header-top-links"
            >
              <TopLinks
                links={this.topLinksData}
                isNewPreNavVariantActive={this.isNewPreNavVariantActive}
              />
            </div>
          </div>
          <div className={classNames(styles.greenBar)} />
          <div
            className={classNames(styles.logoRow, {
              [styles.logoRow__active]: this.state.isFocused,
              [styles.logoRow__tn]: this.isTabbedNavActive,
              [styles.logoRow__grouped]: this.isGroupedActive,
            })}
            data-testid="header-logo-row"
          >
            <LogoBP
              splashScreen={this.state.isFocused}
              isTabbedNavActive={this.isTabbedNavActive}
            />
            <div
              className={splashClasses}
              id="mobile-search"
              data-testid="mobile-search"
            >
              <Search
                hideSearch={
                  (!this.isPersistMobileSearch ? showNav : null) ||
                  (this.peepHeader && !isVisible)
                }
                id="mobileSearch"
                onFocus={this.searchInputFocus}
                isTypeaheadOpen={this.state.isFocused}
                isTabbedNavActive={this.isTabbedNavActive}
                onCloseButtonClick={this.searchInputBlur}
                onTabOut={this.onSearchTabOut}
              />
            </div>
            <BrandPromiseCloseButton
              isTypeaheadOpen={this.state.isFocused}
              onCloseButtonClick={this.searchInputBlur}
              isDesktopCloseButton={false}
            />
            <UserTools
              isMenuOpen={showNav}
              isTabbedNavActive={this.isTabbedNavActive}
              onMenuButtonClick={e => this.onMenuButtonClick(e)}
              isTypeaheadOpen={this.state.isFocused}
              isFallbackMenuTestActive={this.isFallbackMenuTestActive}
            />
          </div>
          <BrandPromiseOverlay
            isTypeaheadOpen={this.state.isFocused}
            onClickAway={this.searchInputBlur}
            isWhiteTypeaheadActive={this.isWhiteTypeaheadActive}
          />
          <hr
            data-testid="typeahead-separator"
            className={typeaheadSeparatorClasses}
          />
        </div>
        {showNav && (
          <div
            className={overlayClasses}
            onClick={e => this.onMenuButtonClick(e)}
            role="button"
            tabIndex={0}
            onKeyPress={this.handleOverlayKeyPress}
          />
        )}
      </div>
    );
  }
}

ResponsiveHeader.contextType = EnvVarsFeaturesContext;

export default ResponsiveHeader;
